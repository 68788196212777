<template>
    <CModal
      :title="title"
      :color="color"
      :show.sync="userModal"
    >   
        <CForm>
            
            <CRow>
              <CCol md="12">
                <CInput
                  label="Whatsapp:"
                  :lazy="false"
                  :value.sync="$v.form.phone.$model"
                  :isValid="checkIfValid('phone')"
                  placeholder=""
                  autocomplete="given-phone"
                  invalidFeedback="Este campo debe tener un número de Whatsapp de 10 dígitos."
                  @input="changePhone"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  label="Puesto de Trabajo:"
                  :lazy="false"
                  :value.sync="$v.form.job_position.$model"
                  :isValid="checkIfValid('job_position')"
                  placeholder=""
                  autocomplete="given-job_position"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
              <CCol md="6">
                <CSelect
                  label="Dirección"
                  :value.sync="$v.form.direction.$model"
                  :options="directionOptions"
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton color="danger" @click="resetModal()" style="float: left;" v-if="form.id > 0">Resetear Contraseña</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
        <ResetCollaboratorModal ref="resetModal" @reset="resetProcess"></ResetCollaboratorModal>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import directions from '../../services/directions';
import collaborators from '../../services/collaborators';
import ResetCollaboratorModal from './ResetCollaboratorModal.vue'

export default {
  name: 'CollaboratorModal',
  props: {
    permissions: Array
  },
  components: { ResetCollaboratorModal },
  data () {
    return {
      userModal: false,
      title: "Nuevo Colaborador",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Permisos del Acceso'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      directions: [],
      directionOptions: []
    }
  },
  mounted: async function() {
      let response = await directions.get(); 

      if(response.type == "success"){
        this.directions = response.data;
      }

      this.directionOptions = await this.$parseSelectOptionsOrdered(this.directions, "id", "name", [{"label": "Todas","value":"0"}]);
  },
  methods: {
    async changePhone (event) {
      if(event.length == 10){
        let response = await collaborators.getByPhone(event); 

        if(response.type == "success"){
          let user = response.data.user;
          this.form.name = user.name;
          this.form.job_position = user.job_position;
          this.form.direction = user.direction_id;
        }
      }
    },
    resetModal () {
      this.$refs.resetModal.showModal(this.form);
    },
    storeModal () {
      this.userModal = true;
      this.color = "info";
      this.title = 'Nuevo Colaborador';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          name: '',
          phone: '',
          job_position: '',
          direction: '0'
      }
    },
    updateModal (item) {
      this.userModal = true;
      this.color = "warning";
      this.title = 'Editar Colaborador';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          name: item.name,
          phone: item.phone,
          job_position: item.job_position,
          direction: item.direction_id
      };
    },
    resetProcess(form){
      this.$emit("reset", form );

      this.closeModal();
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.userModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
          phone: '',
          job_position: '',
          direction: '0'
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      job_position: {

      },
      direction: {

      }
    }
  },
}
</script>